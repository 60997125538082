<template>
  <div v-if="showButtonPendingMovements">
    <v-tooltip left color="primary">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        :style="styleNotificationBtn"
        color="red"
        @click="openModalPendingMovements"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon color="white">fas fa-exclamation-triangle</v-icon>
      </v-btn>
    </template>
    <span>Movimentações que precisam de atenção!</span>
  </v-tooltip>

    <ModalCleanSlot ref="PendingMovements" :widthModal="1000" :heightCard="800">
      <div slot="card" class="teste">
        <v-card-title class="primary--text text-h6">
          Alerta de Movimentações Pendentes
        </v-card-title>

        <v-card-text>
          <v-data-table
            :headers="headers"
            :items="pendingMovements"
            :items-per-page="10"
            item-key="status"
            class="elevation-1"
          >
            <template v-slot:[`item.status`]="{ item }">
              <span>{{ formattedStatus(item.status) }}</span>
            </template>
            <template v-slot:[`item.quantity`]="{ item }">
              <span>{{ item.quantity }}</span>
            </template>
            <template v-slot:[`item.actions`]="{ item, index }">
              <div>
                <v-btn
                  color="primary"
                  text
                  :loading="loadingButton === index"
                  :disabled="loadingButton !== false && loadingButton !== index"
                  @click="viewMovements(item, index)"
                >
                  Ver movimentações
                  <v-icon class="ml-2" color="primary" style="font-size: 21px">mdi mdi-arrow-right</v-icon>
                </v-btn>
              </div>
            </template>
          </v-data-table>
        </v-card-text>

        <v-card-actions class="px-0">
          <v-col cols="12" align="end">
            <v-btn
              large
              outlined
              color="textPrimary"
              @click="closeModalPendingMovements"
            >
              <v-icon class="mr-3" color="primary" style="font-size: 21px">mdi mdi-close</v-icon>
              Fechar
            </v-btn>
          </v-col>
        </v-card-actions>
      </div>
    </ModalCleanSlot>
  </div>
</template>

<script>
import ModalCleanSlot from '@/components/Modals/ModalCleanSlot.vue';
import MovementRecordService from '@/services-http/sdi/MovementRecordService';
import Formatters from '@/shared/formatters/formatters';

export default ({
  name: 'ButtonPendingMovements',

  components: { ModalCleanSlot },

  data: () => ({
    showButtonPendingMovements: false,
    loadingButton: false,
    pendingMovements: [],
    headers: [
      {
        text: 'Status',
        value: 'status',
        sortable: false,
      },
      {
        text: 'Quantidade',
        value: 'quantity',
        sortable: false,
      },
      {
        text: '',
        value: 'actions',
        align: 'end',
        sortable: false,
      },
    ],
  }),

  props: {
    isRHProtegido: {
      type: Boolean,
      default: false,
    },
  },

  created() {
    this.formatter = new Formatters();
  },

  mounted() {
    this.getPendingMovements();
  },

  computed: {
    styleNotificationBtn() {
      return {
        'border-top-left-radius': '10%',
        'border-bottom-left-radius': '10%',
        'border-top-right-radius': '0%',
        'border-bottom-right-radius': '0%',
        'box-shadow': '-0.25rem 0 1rem #00000026',
        'z-index': 999,
        position: 'fixed',
        top: this.$route.name === 'HomeRHProtegido' ? '4.6rem' : '5.2%',
        right: 0,
        transform: 'translateY(-50%)',
      };
    },
  },

  methods: {
    getPendingMovements() {
      this.pendingMovements = [];
      const movementRecordSerive = new MovementRecordService();
      movementRecordSerive.FindPendingMovements(this.isRHProtegido).then((response) => {
        if (response && response.data && response.data.length > 0) {
          this.showButtonPendingMovements = true;
          this.formatterPendingMovements(response.data);
        } else {
          this.showButtonPendingMovements = false;
        }
      }).catch((error) => {
        console.error(error);
      });
    },
    formatterPendingMovements(response) {
      this.pendingMovements = response.map((pending) => ({
        ...pending,
        quantity: pending.movementIds.length,
      }));
    },
    formattedStatus(status) {
      let text = '';

      if (status) {
        text = this.formatter.formatStatusMessage(status);
      }

      switch (status) {
        case 'VALID':
        case 'ELIGIBLE':
        case 'ADJUSTED':
        case 'SUBMITTED':
          text += ' (há 3 dias ou mais)';
          break;

        default:
          break;
      }

      return text;
    },
    openModalPendingMovements() {
      this.$refs.PendingMovements.open();
    },
    closeModalPendingMovements() {
      this.$refs.PendingMovements.close();
    },
    viewMovements(movement, index) {
      if (movement) {
        this.setLoadingButton(index);
        this.setOverlay(true);

        const { defaultQueryParams, defaultOnlyParameterFilter } = this.prepareDefaultParams(movement);

        this.setSearchParamsAndFilter(defaultQueryParams, defaultOnlyParameterFilter);

        this.handleRedirectOrRefresh(this.isRHProtegido);
      }
    },
    prepareDefaultParams(movement) {
      const status = [];

      if (movement.status) {
        switch (movement.status) {
          case 'INVALID':
            status.push('INVALID');
            break;
          case 'INVALID_IN_CARRIER':
            status.push('INVALID_IN_CARRIER');
            break;
          case 'VALID':
            status.push('VALID', 'ELIGIBLE', 'ADJUSTED');
            break;
          case 'TRANSFORMED':
          case 'WAIT_RULES_VALIDATION':
          case 'WAIT_MOVEMENT_TYPE_CLASSIFICATION':
            status.push('TRANSFORMED', 'WAIT_RULES_VALIDATION', 'WAIT_MOVEMENT_TYPE_CLASSIFICATION');
            break;
          default:
            status.push(movement.status);
            break;
        }
      }

      return {
        status,
        defaultQueryParams: {
          page: 1,
          size: 25,
          selectedDate: 'noDate',
          dateType: [],
          sort: 'id,desc',
          pendingMovements: true,
          status,
          tab: 'movements',
        },
        defaultOnlyParameterFilter: {
          movementType: [],
          dateType: [],
          insuranceCarrierIds: [],
          appOrigin: 'allChannels',
          pendingMovements: true,
          status,
        },
      };
    },
    setSearchParamsAndFilter(queryParams, onlyParameterFilter) {
      sessionStorage.setItem('searchParams', JSON.stringify(queryParams));
      sessionStorage.setItem('onlyParameterFilter', JSON.stringify(onlyParameterFilter));
    },
    handleRedirectOrRefresh(isRHProtegido) {
      if (this.$route.name !== 'Movement') {
        setTimeout(() => {
          this.$emit('redirectRouter', 'Movement', { isRHProtegido }, { movement: 'movement' });
          this.setOverlay(false);
        }, 800);
      } else {
        setTimeout(() => {
          this.$emit('isPendingMovements');
        }, 800);
      }
    },
    setOverlay(value) {
      this.$emit('setOverlay', value);
    },
    setLoadingButton(value) {
      this.loadingButton = value;

      if (value === false) {
        this.closeModalPendingMovements();
      }
    },
  },
});
</script>
